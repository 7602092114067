// BAUMIT
$baumit-red: #f00a0d;
$baumit-gray: #2f435b;
$bg-black: #171e24;
$white-text: #FFFFFF;
$light-gray: #797a7c;
$light-border: #EEEEEE;
$navbar-padding-x-lg: 2rem;

// BOOTSTRAP
$font-family-sans-serif: 'Libre Franklin', sans-serif;
$primary: $baumit-red;
$body-color: $baumit-gray;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$input-focus-box-shadow: none;
$form-group-margin-bottom: 3rem;

$btn-font-weight: 800;
$btn-padding-y: 12px;
$btn-padding-x: 20px;

// $navbar-padding-y: 0;
//$navbar-padding-x: 2rem;
$navbar-nav-link-padding-x: 1rem;
// $nav-link-padding-y: 2.6rem;
// $navbar-brand-padding-y: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1750px
);

@mixin blackgrid($pos: 'tl', $p1: '0', $p2: '0', $v: 'b') {
  @include media-breakpoint-up(lg) {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      @if ($pos == 'tl') {
        top: $p1;
        left: $p2;
      } @else {
        bottom: $p1;
        right: $p2;
      }
      width: 255px;
      height: 255px;
      background: transparent url('../img/gridi-black.png') 100% no-repeat;
      @if ($v == 'w') {
        background-image: url('../img/gridi.png');
      }
    }
  }
}