.nominants {
    h1 {
        font-size: 2rem;
        margin: 1rem;
        text-align: center;
        font-weight: 700;
        span {
            display: block;
            font-weight: 300;
        }
    }
    h3 {
        font-size: 1.6rem;
        font-weight: 500;
        padding: 20px 0 10px;
    }
    h4 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 300;
    }
    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 3.2rem;
            padding: 150px 0 0;
            text-align: center;
        }
        h3 {
            font-size: 2rem;
            font-weight: 700;
            padding: 100px 0 30px;
        }
        h4 {
            font-size: 2rem;
            font-weight: 300;
            padding: 0 0 25px;
        }
    }
    .cube__content-wrapper {
        background-color: $bg-black;
        color: $white;
        overflow: hidden;
        @include blackgrid(tl, -60px, -60px);
        .container {
            @include blackgrid(br, -90px, -70px);
        }
        form {
            margin: 100px auto 0;
            text-align: center;
        }
        .project-images {
            margin: 50px 0;
            height: 400px;
            img {
                height: 300px;
                margin-top: 50px;
            }
            .slick-slide {
                opacity: 0.4;
            }
            .slick-active {
                opacity: 0.4;
            }
            .slick-current {
                opacity: 1;
                z-index: 9;
                position: relative;
                img {
                    height: 400px;
                    margin-top: 0;
                    box-shadow: 0 0 100px rgba(0, 0, 0, 0.6);
                }
            }
            .slick-arrow {
                position: absolute;
                bottom: -40px;
                left: 50%;
                width: 50px;
                height: 40px;
                background-color: $baumit-red;
                cursor: pointer;
                z-index: 11;
                &:before {
                    font: normal normal normal 14px/1 FontAwesome;
                    color: $white;
                    position: absolute;
                    top: 3px;
                    font-size: 2rem;
                    left: 35%;
                }
                &.slick-prev {
                    margin-left: -51px;
                    &:before {
                        content: "\f104";
                    }
                }
                &.slick-next {
                    &:before {
                        content: "\f105";
                    }
                }
                &.slick-disabled {
                    opacity: 0.4;
                }
            }
            
        }
    }
    .project-category {
        .cube-slider.slider-nav .slick-slide .cube__thumb {
            border-color: $white;
            
            &.mention1:after {
                content: "";
                background-image: url('../img/price2.png');
                width: 129px;
                height: 129px;
                left: 0px;
                top: 0px;
                position: absolute;
            }
            &.winner1:after {
                content: "";
                background-image: url('../img/price1.png');
                width: 129px;
                height: 129px;
                left: 0px;
                top: 0px;
                position: absolute;
            }
            &.kategory1:after, &.kategory2:after, &.kategory3:after, &.kategory4:after, &.kategory5:after {
                content: "";
                position: absolute;
                width: 75px;
                height: 75px;
                left: 5px;
                top: 5px;
                background-size: cover;
                background-position: center;
            }
            &.mention1.kategory1:after {
                background-image: url('../img/mention_kat1.png');
            }
            &.director1.kategory1:after {
                background-image: url('../img/director_kat1.png');
            }
            &.winner1.kategory1:after {
                background-image: url('../img/winner_kat1.png');
            }
            &.mention1.kategory2:after {
                background-image: url('../img/mention_kat2.png');
            }
            &.director1.kategory2:after {
                background-image: url('../img/director_kat2.png');
            }
            &.winner1.kategory2:after {
                background-image: url('../img/winner_kat2.png');
            }
            &.mention1.kategory3:after {
                background-image: url('../img/mention_kat3.png');
            }
            &.director1.kategory3:after {
                background-image: url('../img/director_kat3.png');
            }
            &.winner1.kategory3:after {
                background-image: url('../img/winner_kat3.png');
            }
            &.mention1.kategory4:after {
                background-image: url('../img/mention_kat4.png');
            }
            &.director1.kategory4:after {
                background-image: url('../img/director_kat4.png');
            }
            &.winner1.kategory4:after {
                background-image: url('../img/winner_kat4.png');
            }
            &.mention1.kategory5:after {
                background-image: url('../img/mention_kat5.png');
            }
            &.director1.kategory5:after {
                background-image: url('../img/director_kat5.png');
            }
            &.winner1.kategory5:after {
                background-image: url('../img/winner_kat5.png');
            }
        }
        .cube__content-wrapper {
            max-height: 10px;
            padding: 0;
            background-color: $white;
            transition: max-height 300ms ease-in-out;
        }
        &.active {
            .cube-slider.slider-nav .slick-current .cube__thumb {
                border-color: $bg-black;
                cursor: default;
            }
            .cube__content-wrapper {
                max-height: 2000px;
                padding: 40px 0;
                background-color: $bg-black;
                @include media-breakpoint-up(lg) {
                    padding: 100px 0 80px;
                }
            }
        }
    }
    
    .project-info {
        
        max-width: 600px;
        .row > div:first-child {
            color: #888888;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.3rem;
        }
    }
    .cube-slider.slider-nav {
        .cube__thumb {
            cursor: pointer;
            .thumb__title {
                //position: static;
                font-size: 1.2rem;
                text-align: left;
                //opacity: 1;
                background-color: rgba($color: $white, $alpha: 0.8);
                padding-left: 20px;
                //max-width: 270px;
                line-height: 1.2;
                opacity: 1;
                height: 70px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            img {
                object-fit: cover;
                //width: 273px;
                //height: 203px;
            }
        }
    }
}
.vote-form {
    .vote-form__stars {
        i {
            font-size: 1.55rem;
            color: $light-gray;
            &.active {
                color: $white;
            }
        }
    }
    .vote-form__submit {
        padding: 15px 0 0;
        i {
            font-size: 1.4rem;
            margin-right: 5px;
        }
    }
    @include media-breakpoint-up(md) {
        .vote-form__stars {
            padding: 10px 15px 15px 0;
            text-align: right;
        }
        .vote-form__submit {
            padding: 10px 0 15px 15px;
            border-left: 1px solid $light-gray;
            text-align: left;
        }
    }
}
.winners-info {
    text-align: center;
    .container {
        margin-top: 60px;
        border-top: 1px solid $light-border;
    }
    h2 {
        padding: 60px 0 20px;
    }
    p {
        font-size: 1.2rem;
    }
    .maindate {
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
    }
    .form__conditions {
        margin-top: 30px;
        a {
            font-weight: bold;
        }
    }
    @include media-breakpoint-up(md) {
        .container {
            margin-top: 120px;
        }
        h2 {
            padding-top: 120px;
        }
    }
}