@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:100,200,300,400,500,600,700,800,900&subset=latin-ext');
@import "font-awesome.min";
@import "_variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/simplebar/dist/simplebar";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "_slick-theme";
@import "_navbanner";
@import "_home";
@import "_forms";
@import "_nominants";
@import "_footer";
@import "_cookiebar";