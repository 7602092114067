.divider {
    background: transparent url('../img/footer-divider.png') bottom left / 1000px no-repeat;
    min-height: 200px;
    img {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        background: transparent;
        img {
            display: inline-block;
            width: 100%;
        }
    }
}
.press {
    padding-bottom: 50px;
    h2 {
        padding: 50px 0 0;
    }
    h3 {
        margin: 2rem 0 2rem;
        font-size: 1.5rem;
    }
    text-align: center;
    .pressgroup {
        margin-right: -15px;
        margin-left: -15px;
        img {
            margin: 15px;
        }
    }
    img {
        max-width: 100px;
    }
    @include media-breakpoint-up(sm) {
        img {
            max-width: 160px;
        }
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 150px;
        h2 {
            padding: 160px 0 0;
        }
        h3 {
            margin: 4rem 0 2rem;
            font-size: 2rem;
        }
        img {
            max-width: 500px;
        }
    }
}
footer {
    background-color: $bg-black;
    padding: 70px 0 40px;
    color: $white-text;
    text-align: center;
    .col-lg {
        padding-bottom: 50px;
    }
    h4 {
        font-weight: 600;
        font-size: 1.3rem;
    }
    p {
        margin: 5px 0;
    }
    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}