.cookies-banner {
    background: linear-gradient(#9C9D9D, #9C9D9D);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 15px;
    color: #FFF;
    z-index: 9;
}

.cookies-banner a {
    color: #FFF;
    font-weight: 700;
}

.cookies-banner a:hover {
    color: #000;
}

@media (max-width: 991px) {
    .cookies-banner {
      text-align: center;
    }
    .cookies-banner .cc-highlight {
        margin-top: 20px;
    }
}
@media (min-width: 992px) {
    .cookies-banner .cc-highlight {
        text-align: right;
    }
}

.btn-custom, .cc-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  text-transform: uppercase;
}
  .btn-custom:before, .cc-btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.btn-custom:hover:before, .cc-btn:hover:before, .btn-custom:focus:before, .cc-btn:focus:before, .btn-custom:active:before, .cc-btn:active:before {
    -webkit-transform: scale(2);
    transform: scale(2);
}
.cc-btn {
  font-weight: 600;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  background: #EC1C23;
  color: #FFF;
  /* border-radius: 18px; */
  /* border: 1px solid $color-black; */
}
  .cc-btn:before {
    border-radius: 18px;
}