
// NAVIGATION
.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 9;
    @include media-breakpoint-up(lg) {
        position: static;
    }
    .dropdown {
        position: relative;
        display: inline-block;
        cursor: pointer;
        &:hover {
            .dropdown-content {
                display: block;
            }
        }
        .dropdown-content {
            display: none;
            position: absolute;
            min-width: 100%;
            z-index: 10;
            padding: 0;
            left: 0;
            top: 110px;
            
            .dropdown-item {
                background: $white;
                padding: 0;
                text-align: center;
                a {
                    padding-top: 1.6rem;
                    padding-bottom: 1.6rem;
                }
            }
        }
    }

}
@media only screen and (max-width: 992px) {
    .dropdown-content {
        top: 42px!important;
    }
}
.nav-link {
    color: $baumit-gray;
    border-bottom: 3px solid transparent;
    &:hover, &.active {
        color: $baumit-gray;
        border-color: $baumit-red;
    }
    &.active {
        font-weight: 700;
    }
}

.navbar-brand img {
    height: 50px;
}
.verical-down {
    display: none;
}
@include media-breakpoint-up(lg) {
    .navbar-brand:first-child {
        margin-right: 1rem;
    }
    .navbar {
        padding: 0 $navbar-padding-x-lg 0 100px;
    }
    .navbar-brand {
        img {
            height: auto;
        }
    }
    .nav-link {
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;
    }
}
@media (min-width: 1300px) {
    .navbar-brand:first-child {
        margin-right: 3rem;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.3rem;
        padding-left: 1.3rem;
    }
}
.navbar-toggler-icon {
    border-top: 1px solid $baumit-red;
    border-bottom: 1px solid $baumit-red;
    position: relative;
    height: 1.2rem;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 70%;
        height: 1px;
        background-color: $baumit-red;
        
    }
}

// BANNER
.mainbanner {
    max-height: 700px;
    overflow: hidden;
    position: relative;
    margin-top: 72px;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding-left: 100px;
        .verical-down {
            display: block;
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 100px;
            height: calc(100% + 100px);
            cursor: pointer;
            span {
                transform: rotate(-90deg);
                bottom: 150px;
                left: -45px;
                position: absolute;
                text-transform: uppercase;
                color: $baumit-red;
                width: 200px;
                &:after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 80px;
                    background-color: $baumit-red;
                    top: 8px;
                    left: -100px;
                }
            }
        }
    }
    img {
        width: auto;
        height: 100%;
        position: absolute;
        bottom: 0;
        @include media-breakpoint-up(lg) {
            position: static;
            width: 100%;
            height: auto;
        }
        @media (min-width: 1820px) {
            height: 700px;
            object-fit: cover;
        }
    }
    &__info {
        display: block;
        color: $body-color;
        background-color: $white-text;
        opacity: 0.85;
        margin-left: 5%;
        width: 90%;
        padding: 2rem;   
        box-shadow: 2px 14px 16px rgba(0, 0, 0, 0.2);
        margin-bottom: 30%;
        a {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 12%;
            right: $navbar-padding-x-lg;
            max-width: 740px;
            padding: 3rem;
            margin-bottom: 0;  
            h3 {
                font-size: 3.5rem;
            }
            p {
                font-size: 1.4rem;
                margin: 0;
            }
            i {
                color: $baumit-red;
                font-size: 1.4rem;
            }
            a {
                display: block;
                margin: 1rem 0 0;
            }
        }
        @include media-breakpoint-up(xl) {
            top: 20%;
        }
    }
}
