.categories {
    background-color: $bg-black;
    color: $white-text;
    text-align: center;
    padding-bottom: 50px;
    @include media-breakpoint-up(xl) {
        padding-bottom: 160px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: -100px;
            left: -100px;
            width: 255px;
            height: 255px;
            background: transparent url('../img/gridi-black.png') 100% no-repeat;
        }
    }
    h2 {
        padding-bottom: 30px;
    }
    h3 {
        font-size: 20px;
        margin: 0 0 0 20px;
        text-align: left;
    }
    p {
        @include media-breakpoint-up(xl) {
            font-size: 1.3rem;
            line-height: 1.8;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                bottom: -520px;
                right: -150px;
                width: 255px;
                height: 255px;
                background: transparent url('../img/gridi-black.png') 100% no-repeat;
            }
        }
    }
    .row {
        margin-top: 40px;
        @include media-breakpoint-up(xl) {
            > div {
                width: 205px;
                margin-top: 40px;
                margin-bottom: 20px;
            }
            h3 {
                margin: 3px 0 0;
            }
        }
    }
    
    .category {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        img {
            max-width: 150px;
            @media (min-width: 400px) {
                max-width: 198px;
            }
        }
        @include media-breakpoint-up(xl) {
            display: block;
            h3 {
                text-align: center;
            }
        }
    }
}
.container--form {
    max-width: 700px;
    h2 {
        padding-bottom: 30px;
    }
    p.subheader {
        text-align: center;
        font-size: 1.5rem;
        margin: 0 auto;
        padding: 0 0 70px;
        font-weight: 300;
    }
    h3 {
        font-size: 1.6rem;
        font-weight: 500;
        span {
            font-weight: 300;
            font-size: 1.2rem;
        }
    }
    form {
        margin: 30px 0 150px;
    }
    .form__conditions {
        text-align: center;
        margin-top: 30px;
        font-weight: bold;
    }
    .form__required {
        font-style: italic;
    }
    legend {
        font-size: 18px;
    }
    fieldset {
        margin: 0 0 40px 0;
    }
    .form-control {
        border-top: none;
        border-left: none;
        border-right: none;
        &:focus {
            border-bottom-width: 2px;
        }
    }
    label {
        color: $gray-500;
    }
    label.form-check-label {
        color: $body-color;
    }
    fieldset .form-check {
        margin-bottom: 0.3rem;
        input {
            margin-top: 0.15rem;
        }
    }
    fieldset.is-invalid .form-check-label {
        color: #FF0000;
    }
    .form-check {
        margin-bottom: 1.5rem;
    }
    .photoinputs {
        .form-control-file {
            border-bottom: 1px solid $input-border-color;
            padding-bottom: 5px;
        }
        .form-group {
            &:not(:first-child) {
                display: none;
            }
        }
    }
    .invalid-feedback {
        position: absolute;
    }
    .form-footer {
        display: flex;
        justify-content: space-between;
        &.form-footer--single {
            justify-content: space-around;
        }
        div {
            line-height: 50px;
            font-size: 18px;
            font-weight: 800;
        }
    }
}
.container--thanks {
    h1 {
        font-size: 3.6rem;
        padding: 160px 0 40px;
    }
    p {
        font-size: 1.3rem;
    }
    text-align: center;
    .thanks__check {
        max-width: 300px;
        width: 60%;
        margin: 0px 0 20px;
    }
    .palce {
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 700;
    }
    @include media-breakpoint-up(sm) {
        .thanks__check {
            margin: 40px 0 60px;
        }
        .palce {
            font-size: 1.8rem;
        }
    }
}