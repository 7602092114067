.cube-slider {
    height: 300px;
    @include media-breakpoint-up(sm) {
        padding: 0 110px;
        transition: padding-left 300ms;
        &.begining {
            padding-left: 0;
        }
        overflow: hidden;
        .slick-list {
            overflow: visible;
        }
    }
    .cube__thumb {
        border: 14px solid $white;
        position: relative;
        .thumb__title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            font-size: 2rem;
            text-align: center;
            line-height: 70px;
            opacity: 0.55;
            background-color: $white;
        }
        img {
            object-fit: cover;
            width: 273px;
            height: 273px;
        }
    }
    .slick-current .cube__thumb {
        border-color: $bg-black;
    }
    .slick-prev, .slick-next {
        position: absolute;
        top: 0;
        width: 110px;
        height: 100%;
        padding: 0;
        cursor: pointer;
        z-index: 9;
        pointer-events: none;
        &.slick-disabled {
            display: none;
            opacity: 0;
            pointer-events: none;
        }
    }
    @include media-breakpoint-up(sm) {
        .slick-prev, .slick-next {
            pointer-events: auto;
            &.slick-disabled {
                display: none;
            }
        }
    }
    .slick-prev:before, .slick-next:before {
        font: normal normal normal 14px/1 FontAwesome;
        opacity: .5;
        color: $bg-black;
        position: absolute;
        top: 30%;
        font-size: 6rem;
        left: 35%;
    }
    .slick-next {
        right: 0;
        @include media-breakpoint-up(sm) {
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            &:before {
                content: "\f105";
            }
        }
    }
    .slick-prev {
        left: 0;
        @include media-breakpoint-up(sm) {
            background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
            &:before {
                content: "\f104";
            }
        }
    }
}