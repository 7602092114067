h2 {
    text-align: center;
    padding: 50px 0 30px;
    font-size: 2rem;
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
        padding: 160px 0 100px;
    }
}
h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
        margin-bottom: 2rem;
    }
}
.dont-break {
    white-space: nowrap;
}
.basicinfo {
    text-align: center;
    background: transparent url('../img/left-buildings.png') bottom left no-repeat;
    background-size: 20%;
    padding: 50px 10px 100px;
    h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }
    p {
        strong {
            display: block;
            font-weight: 600;
            margin: 20px 0 15px;
        }
    }
    @include media-breakpoint-up(lg) {
        margin: 100px 0 0;
        padding: 180px 0;
        text-align: center;
        background-repeat: no-repeat;
        background-position: calc(50vw - 780px) center, calc(50vw + 410px) center;
        background-image: url('../img/left-buildings.png'), url('../img/right-buildings.png');
        background-size: auto;
        min-height: 747px;
        .container-basic {
            max-width: 700px;
        }
        h1 {
            font-size: 3rem;
            margin-bottom: 2rem;
            strong {
                display: block;
                font-weight: 800;
            }
        }
        p {
            font-size: 1.3rem;
            line-height: 1.8;
            strong {
                margin: 20px 0 30px;
            }
        }
    }
    @include media-breakpoint-up(xxl) {
        background-position: left center, right center;
    }
}
.judges {
    margin-top: 100px;
    background-color: $bg-black;
    padding-bottom: 50px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 160px;
    }
    .container {
        max-width: 990px;
    }
    h2 {
        color: $white-text;
        @include blackgrid(br, -180px, -100px);
    }
    .judge {
        width: 300px;
        height: 410px;
        position: relative;
        margin: 0 auto $grid-gutter-width;
        overflow: hidden;
        img {
            width: 100%;
            position: absolute;
        }
        &__info {
            position: absolute;
            left: 0;
            top: 320px;
            width: 100%;
            height: 100%;
            background-color: $white;
            padding: 15px 30px;
            transition: top 200ms ease-in-out;
            opacity: 0.9;
            h3 {
                min-height: 75px;
                font-size: 1.4rem;
                margin: 0;
            }
            &.open {
                top: 0;
            }
        }
    }
}
.news {
    .container {
        position: relative;
    }
    @include media-breakpoint-up(lg) {
        h2 {
            position: relative;
            z-index: 10;
            &:after {
            content: "";
            pointer-events: none;
                position: absolute;
                bottom: -500px;
                left: -125px;
                width: 255px;
                height: 255px;
                background: transparent url('../img/gridi.png') 100% no-repeat;
            }
        }
    }
    .actuality {
        width: 290px;
        color: $baumit-gray;
        padding-bottom: 40px;
        img {
            max-width: 100%;
            height: 292px;
        }
        a:last-child {
            color: $primary;
            cursor: pointer;
            i {
                margin-left: 5px;
            }
        }
    }
    h3 {
        margin: 24px 0;
        line-height: 1.5;
        font-size: 1.5rem;
    }
    p {
        line-height: 1.8;
    }
    &__simplebar {
        width: 100%;
    }
    .row-simplebar {
        width: 1000px;
    }
    .arrow-left, .arrow-right {
        position: absolute;
        top: 125px;
        width: 100px;
        height: calc(100% - 210px);
        z-index: 9;
        cursor: pointer;
        display: none;
        pointer-events: none;
    }
    .arrow-left:before, .arrow-right:before {
        font: normal normal normal 14px/1 FontAwesome;
        opacity: .4;
        color: $bg-black;
        position: absolute;
        top: 255px;
        font-size: 5rem;
        left: 35%;
    }
    @include media-breakpoint-up(lg) {
        .arrow-left, .arrow-right {
            top: 320px;
            height: calc(100% - 340px);

        }
    }
    @include media-breakpoint-up(sm) {
        .arrow-left, .arrow-right {
            pointer-events: auto;
        }
    }
    .arrow-right {
        right: 0;
        @include media-breakpoint-up(sm) {
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
            &:before {
                content: "\f105";
            }
        }
    }
    .arrow-left {
        left: 0;
        @include media-breakpoint-up(sm) {
            background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0));
            &:before {
                content: "\f104";
            }
        }
    }
}

// SCROLLBAR
.simplebar-track.simplebar-horizontal {
    background-color: #eaecee;
    height: 1px;
    .simplebar-visible {
        top: -1px;
        height: 3px;
        &:before {
            background-color: $baumit-red;
            border-radius: 0;
            opacity: 1;
            box-shadow: 5px 5px 5px rgba(240, 10, 13, 0.5);
        }
    }
}
.simplebar-content {
    max-height: 1000%;
}

.cube {
    @include media-breakpoint-up(lg) {
        p {
            font-size: 1.2rem;
        }
    }
    h2 {
        padding-bottom: 30px;
        ~ p {
            margin-bottom: 70px;
        }
    }
    .cube__content-wrapper {
        background-color: $bg-black;
        color: $white;
        padding: 100px 0 80px;
        @include blackgrid(tl, -60px, -60px);
        .container {
            @include blackgrid(br, -90px, -70px);
        }
        h3 {
            font-size: 2rem;
            font-weight: 300;
            margin: 0 0 30px;
        }
        h4 {
            font-size: 1.4rem;
            font-weight: 500;
            margin: 0 0 30px;
            color: #888888;
        }
        p {
            line-height: 2;
        }
    }
}
.categorywinners {
    h1 {
        text-align: center;
        padding: 80px 0 0px;
        strong {
            display: block;
        }
    }
    .homenominants__item {
        width: 100%;
        color: $body-color;
        img {
            width: 100%;
        }
        .homenominants__info {
            position: relative;
            display: flex;
            width: 100%;
            flex-direction: column;
            overflow: hidden;
        }
        h3 {
            margin: 20px 60px 15px 0;
            font-size: 1.4rem;
        }
        p {
            margin: 0 0 20px 0;
            font-size: 0.95rem;
        }
        i {
            position: absolute;
            top: 50%;
            right: 0;
            border: 1px solid $baumit-red;
            color: $baumit-red;
            width: 50px;
            height: 50px;
            margin-top: -25px;
            border-radius: 50%;
            font-size: 2rem;
            line-height: 44px;
            &:before {
                margin: 18px;
            }
        }
    }
    @include media-breakpoint-up(sm) {
        .homenominants {
            h3 {
                font-size: 1.75rem;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        h1 {
            padding: 150px 0 100px;
            @include blackgrid(br, -300px, calc(50% - 140px), w);
        }
        .homenominants {
            display: flex;
            flex-wrap: wrap;
            margin-top: 300px;
            @include blackgrid(br, 80px, calc(50% - 140px), w);
        }
        .homenominants__item {
            position: relative;
            width: 50%;
            height: 500px;
            background-color: $white;
            &:nth-child(odd) {
                margin-top: -300px;
            }
            img {
                width: 100%;
            }
            .homenominants__info {
                position: absolute;
                bottom: 0;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                background-color: $white;
            }
            h3 {
                margin: 20px 100px 15px 30px;
            }
            p {
                margin: 0 100px 15px 30px;
            }
            i {
                position: absolute;
                right: 20px;
                border: 1px solid $baumit-red;
                color: $baumit-red;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                font-size: 2rem;
                line-height: 44px;
                &:before {
                    margin: 18px;
                }
            }
            &:nth-child(1) {
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.35);
            }
            &:nth-child(2) {
                box-shadow: 10px -10px 35px rgba(0, 0, 0, 0.15);
                z-index: 1;
            }
            &:nth-child(3) {
                box-shadow: -15px 0px 35px rgba(0, 0, 0, 0.15);
            }
            &:nth-child(4) {
                box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.15);
                z-index: 2;
            }
            &:nth-child(5) {
                box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.35);
                z-index: 1;
            }
            &:hover {
                i {
                    background-color: $baumit-red;
                    color: $white;
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .homenominants__item {
            height: 590px;
        }
    }
}
.modal-dialog {
    margin-top: 1.5rem;
    .close {
        margin: 0;
        font-size: 1.8rem;
        padding: 8px 12px;
        position: absolute;
        top: -20px;
        right: 20px;
        background-color: $baumit-red;
        color: $white;
        opacity: 1;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
    .modal-header {
        border-bottom: none;
        padding-top: 2rem;
    }
    .modal-body {
        > img:first-child {
            display: block;
            margin: 0 auto 30px;
            width: 80%;
        }
    }
    .modal-footer {
        border-top: none;
        justify-content: flex-start;
        a[data-dismiss] {
            color: $baumit-red;
            cursor: pointer;
            i {
                margin-right: 5px;
            }
        }
    }
}